import styled, { createGlobalStyle } from "styled-components";
import { cssLock } from "../../helpers/Mixins";

import ProximaNovaLightOTF from "../../assets/fonts/proxima_nova_light.otf";
import ProximaNovaLightTTF from "../../assets/fonts/proxima_nova_light.ttf";
import ProximaNovaLightWOFF from "../../assets/fonts/proxima_nova_light.woff";

import ProximaNovaRegOTF from "../../assets/fonts/proxima_nova_regular.otf";
import ProximaNovaRegTTF from "../../assets/fonts/proxima_nova_regular.ttf";
import ProximaNovaRegWOFF from "../../assets/fonts/proxima_nova_regular.woff";

import ProximaNovaThinOTF from "../../assets/fonts/proxima_nova_thin.otf";
import ProximaNovaThinTTF from "../../assets/fonts/proxima_nova_thin.ttf";
import ProximaNovaThinWOFF from "../../assets/fonts/proxima_nova_thin.woff";
import {mediaMax} from "../../helpers/MediaQueries";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Proxima Nova';
    src: url(${ProximaNovaLightTTF}) format('ttf'),
    url(${ProximaNovaLightOTF}) format('otf'),
    url(${ProximaNovaLightWOFF}) format('woff');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Proxima Nova';
    src: url(${ProximaNovaRegTTF}) format('ttf'),
    url(${ProximaNovaRegOTF}) format('otf'),
    url(${ProximaNovaRegWOFF}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Proxima Nova';
    src: url(${ProximaNovaThinTTF}) format('ttf'),
    url(${ProximaNovaThinOTF}) format('otf'),
    url(${ProximaNovaThinWOFF}) format('woff');
    font-weight: 100;
    font-style: normal;
  }

  body {
    margin: 0;
    background: #fff;
  }
  
  * {
    -webkit-tap-highlight-color: transparent;
  }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}
main, section {
  position: relative;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

[hidden],
template {
  display: none;
}

a {
  text-decoration: none;
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

b,
strong {
  font-weight: inherit;
}


img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
  margin: 0;
}

optgroup {
  font-weight: 700;
}

button,
select {
  text-transform: none;
}

[type="reset"],
[type="submit"],
button,
html [type="button"] {
  -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

html {
  box-sizing: border-box;
  font-family: "Proxima Nova", serif;
}

* {
  box-sizing: inherit;
}

*:before {
  box-sizing: inherit;
}

*:after {
  box-sizing: inherit;
}

img {
  max-width: 100%;
  padding: 0;
  margin: 0;
}

h1 {
  padding: 0;
  margin: 0;
}

h2 {
  padding: 0;
  margin: 0;
}

h3 {
  padding: 0;
  margin: 0;
}

h4 {
  padding: 0;
  margin: 0;
}

h5 {
  padding: 0;
  margin: 0;
}

h6 {
  padding: 0;
  margin: 0;
}

hgroup {
  padding: 0;
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style-position: outside;
  list-style-image: none;
}

ol {
  padding: 0;
  margin: 0;
  list-style-position: outside;
  list-style-image: none;
}

dl {
  padding: 0;
  margin: 0;
}

dd {
  padding: 0;
  margin: 0;
}

p {
  padding: 0;
  margin: 0;
}

figure {
  padding: 0;
  margin: 0;
}

pre {
  margin: 0;
  font-size: 0.85rem;
  line-height: 1.42;
  background: hsla(0, 0%, 0%, 0.04);
  border-radius: 3px;
  overflow: auto;
  word-wrap: normal;
}

fieldset {
  padding: 0;
  margin: 0;
}

blockquote {
  padding: 0;
  margin: 0;
}

form {
  padding: 0;
  margin: 0;
}

noscript {
  padding: 0;
  margin: 0;
}

iframe {
  padding: 0;
  margin: 0;
}

hr {
  padding: 0;
  margin: 0 0 calc(1.45rem - 1px);
  background: hsla(0, 0%, 0%, 0.2);
  border: none;
  height: 1px;
}

b {
  font-weight: bold;
}

strong {
  font-weight: bold;
}

li {
  margin-bottom: calc(1.45rem / 2);
}

ol li {
  padding-left: 0;
}

ul li {
  padding-left: 0;
}
.pb-s {
  padding-bottom: 9.3%;
  ${mediaMax.tablet`
    padding-bottom: 7.3%;
  `};
}
.fs-l {
  font-size: 30px;
  ${mediaMax.phoneXL`
    font-size: 26px;
  `}
}
.fs-lbl {
  font-size: 22px;
}
.fs-lbl-xl {
  font-size: 64px;
   ${cssLock({
     minSize: 40,
     maxSize: 64,
     minWidth: 375,
     maxWidth: 1920,
     property: "font-size",
     unit: "px",
   })};
}
.fs-xl {
  ${cssLock({
    minSize: 34,
    maxSize: 40,
    minWidth: 375,
    maxWidth: 1920,
    property: "font-size",
    unit: "px",
  })};
}
.fs-xxl {
  font-weight: 100;
  ${cssLock({
    minSize: 40,
    maxSize: 160,
    minWidth: 550,
    maxWidth: 1920,
    property: "font-size",
    unit: "px",
  })};
  line-height: .85;
  ${mediaMax.tabletM`
    font-size: 50px;
    line-height: .9;
  `}
}
.reg-text {
  color: #000;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: .4px;
  font-weight: 400;
  &.narrow {
    ${mediaMax.phoneXL`
      letter-spacing: .16px;
    `}
  }
}
.reg-text-xl {
  color: #000;
  ${cssLock({
    minSize: 15,
    maxSize: 16,
    minWidth: 375,
    maxWidth: 1920,
    property: "font-size",
    unit: "px",
  })};
  ${cssLock({
    minSize: 24,
    maxSize: 28,
    minWidth: 375,
    maxWidth: 1920,
    property: "line-height",
    unit: "px",
  })};
  letter-spacing: .4px;
  font-weight: 400;
}
.mob_heading {
  padding: 13vh 12% 0;
  font-size: 30px;
}
.heading {
  color: #C9C3B8;
  font-weight: 100;
  text-transform: uppercase;
  ${mediaMax.tablet`
    font-weight: 100;
  `};
}
.shapes-bg {
  min-height: 100vh;
  background-color: #F4F3EE;
  ${mediaMax.tablet`
    padding: 50px 0;
  `}
  &.light {
    background-color: #fff;
  }
  &:before {
    content: '';
    width: 43.5%;
    height: 41vw;
    background-color: #FD5F1F;
    position: absolute;
    left: -37%;
    top: -3%;
    border-radius: 50%;
    ${mediaMax.phoneXL`
      top:2%; 
      left: -52%;
      width: 60vw;
      height: 65vw;
    `}
  }
  &:after {
    content: '';
    width: 6%;
    height: 76%;
    background-color: #004987;
    position: absolute;
    right: 0;
    bottom: 0;
    clip-path: polygon(100% 0%, 100% 100%, 0% 100%);
    ${mediaMax.phoneXL`
      width: 8%;
      height: 30vh;
    `}
  }
  @keyframes inAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes outAnimation {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

}
`;

export const LayoutWrapper = styled.div`
  transition: 0.8s ease-out;
`;

export const PageWrapper = styled.div``;
