import styled from "styled-components";
import {mediaMax, mediaMin} from "../../helpers/MediaQueries";
import {Link} from "gatsby";

interface IProps {
  menulight: boolean;
}
export const HeaderWrap = styled.div`
  position: fixed;
  width: 100%;
  z-index: 100;
  isolation: isolate;
`;

export const MenuBtnWrap = styled.div<IProps>`
  width: 32px;
  height: 24px;
  position: fixed;
  top: 40px;
  right: 40px;
  cursor: pointer;
  z-index: 11;
  mix-blend-mode: ${props => props.menulight? 'initial' : 'difference'};

  ${mediaMax.tablet`
    top: 32px;
    right: 28px;
  `}
  ${mediaMax.phoneXL`
    top: 20px;
    right: 10px;
  `}
`;
export const ContactBtn = styled(Link)<IProps>`
  display: block;
  position: fixed;
  top: 42px;
  right: 92px;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
  font-weight: 100;
  color: ${props => props.menulight? '#fff' : '#ffffffcc'};
  z-index: 11;
  mix-blend-mode: ${props => props.menulight? 'initial' : 'difference'};
  ${mediaMax.tablet`
    position: absolute;
    top: 24px;
    right: 60px;
    font-size: 18px;
    line-height: 18px;
    font-weight: 300;

  `}
`;
export const MenuBtn = styled.div<IProps>`
  width: 32px;
  height: 2px;
  background-color:  ${props => props.menulight? '#fff' : '#ffffffcc'};
  transition: .4s ease-out;
  position: absolute;
  top: 11px;
  right: 0;
  cursor: pointer;
  
  ${mediaMax.phoneXL`
    width: 28px;
  `}
  &:before {
    content: "";
    width: 32px;
    height: 2px;
    background-color:  ${props => props.menulight? '#fff' : '#ffffffcc'};
    transition: .4s ease-out;
    position: absolute;
    top: -11px;
    right: 0;
    ${mediaMax.phoneXL`
      width: 28px;
    `}
  }
  &:after {
    content: "";
    width: 32px;
    height: 2px;
    background-color:  ${props => props.menulight? '#fff' : '#ffffffcc'};
    transition: .4s ease-out;
    position: absolute;
    top: 11px;
    right: 0;
    ${mediaMax.phoneXL`
      width: 28px;
    `}
  }
`;

export const MenuNavWrap = styled.div`
  padding: 90px 20px 30px 46px;
  background-color: #f4f3ee;
  min-height: 100vh;
  max-width: 375px;
  position: absolute;
  right: -375px;
  overflow: auto;
  box-shadow: 0 0 14px rgba(0,0,0, .2);
  transition: all 1s ease-in-out;
  &.active {
    right: 0;
  }
  ${mediaMax.phoneXL`
    width: 100%;
    max-width: 501px;
    right: -501px;
    padding: 55px 20px 30px 60px;
  `};
`;
export const MenuNav = styled.nav`
  height: calc(100vh - 120px);
  width: 100%;
  max-height: -webkit-fill-available;
  font-family: "Proxima Nova", serif;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${mediaMax.phoneXL`
    height: calc(100vh - 165px);
  `}
  span {
    cursor: default;
    color: #5f5f5f;
    transition: 0.4s ease-in-out;
    &:hover {
      ${mediaMin.tabletLandscape`
        opacity: 0.65;
      `}
    }
  }
  a {
    color: #5f5f5f;
    transition: 0.4s ease-in-out;
    &:hover {
      ${mediaMin.tabletLandscape`
        opacity: 0.65;
      `}
    }
  }
  li {
    list-style: none;
    margin-bottom: 0;
  }
  .back-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    &:hover {
      ${mediaMin.tabletLandscape`
        opacity: 0.6;
      `}
    }
  }
  .top-list {
    width: 100%;
    min-height: 45vh;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.48px;
    text-transform: uppercase;
    height: fit-content;
    span {
      display: flex;
      align-items: center;
      cursor: pointer;
      svg {
        margin-left: 8px;
      }
    }
    > li {
      padding-bottom: 3.6vh;
      ${mediaMax.phoneXL`
        padding-bottom: 35px;
      `}

      &.active .inner-list {
        overflow: visible;
        padding-top: 30px;
        max-height: 160px;
        opacity: 1;
      }
    }

    .inner-list {
      text-transform: capitalize;
      font-size: 18px;
      line-height: 24px;
      max-height: 0;
      overflow: hidden;
      padding-top: 0;
      padding-left: 32px;
      transition: all 0.8s ease-out;
      opacity: 0;
      a {
        color: #000;
      }

      li {
        padding-bottom: 14px;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  .sec-block {
    width: 100%;
    align-self: flex-end;
  }

  .bottom-list {
    width: 100%;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;

    a {
      color: #000;
    }
    li {
      padding-bottom: 1.8vh;
    }
  }

  .socials {
    width: 100%;
    max-width: 70px;
    display: flex;
    justify-content: space-between;
    padding: 1.5vh 0;

    a svg path {
      transition: 0.4s ease-out;
    }
    a:hover svg path {
      ${mediaMin.tabletLandscape`
        fill: #4b4545;
      `}
    }
  }

  .legal {
    width: 100%;
    max-width: 200px;
    display: flex;
    justify-content: space-between;
    svg {
      margin-left: 12px;
    }
    a {
      font-size: 20px;
      line-height: 24px;
      color: #3d3939;
    }
  }
`;
