import styled from "styled-components";
import {mediaMax} from "../../../helpers/MediaQueries";

interface IStylesProps {
  height?: string;
  left?: string;
  right?: string;
  bottom?: string;
  top?: string;
  mobBreakpoint?: string | number;
  mobHeight?: string;
  mobLeft?: string;
  mobRight?: string;
  mobBottom?: string;
  mobTop?: string;
  zIndex?: string;
}

export const CompositionImage = styled.img<IStylesProps>`
  height: ${props => props.height};
  left: ${props => props.left};
  right: ${props => props.right};
  top: ${props => props.top};
  bottom: ${props => props.bottom};

  position: absolute;
  z-index: ${props => props.zIndex ? props.zIndex : 0};
  opacity: 0;
  pointer-events: none;
`;
export const CompositionDiv = styled.div<IStylesProps>`
  height: ${props => props.height};
  left: ${props => props.left};
  right: ${props => props.right};
  top: ${props => props.top};
  bottom: ${props => props.bottom};
  position: absolute;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  ${props => props?.mobBreakpoint && mediaMax[props?.mobBreakpoint]`
    height: ${props.mobHeight ||``};
    left: ${props.mobLeft ||``};
    right: ${props.mobRight ||``};
    bottom: ${props.mobBottom ||``};
    top: ${props.mobTop ||``};
    `}
  &.circle {
    width: ${props => props.height};
    background-color: #FD5F1F;
    border-radius: 50%;

    ${props => props?.mobBreakpoint && mediaMax[props?.mobBreakpoint]`
      width: ${props.mobHeight ||``};
    `}
  }

  &.triangle {
    width: 6%;
    background-color: #004987;
    clip-path: polygon(100% 0%, 100% 100%, 0% 100%);
    ${mediaMax.phoneXL`
      width: 8%;
    `}
  }
`;

export const CompositionWrapper = styled.section`
  overflow: hidden;

  .shapes-bg:before, .shapes-bg:after {
    display: none;
  }
`;
export const MapWrapper = styled.section`
  width: 100%;
  margin: 0 0 9.3%;
  img {
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
  ${mediaMax.phoneXL`
    margin: 0;
  `}
`;