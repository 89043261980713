exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-beach-tower-floor-plans-tsx": () => import("./../../../src/pages/beach-tower-floor-plans.tsx" /* webpackChunkName: "component---src-pages-beach-tower-floor-plans-tsx" */),
  "component---src-pages-beach-tower-residences-tsx": () => import("./../../../src/pages/beach-tower-residences.tsx" /* webpackChunkName: "component---src-pages-beach-tower-residences-tsx" */),
  "component---src-pages-beach-tower-tsx": () => import("./../../../src/pages/beach-tower.tsx" /* webpackChunkName: "component---src-pages-beach-tower-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-film-tsx": () => import("./../../../src/pages/film.tsx" /* webpackChunkName: "component---src-pages-film-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-location-tsx": () => import("./../../../src/pages/location.tsx" /* webpackChunkName: "component---src-pages-location-tsx" */),
  "component---src-pages-marina-tower-floor-plans-tsx": () => import("./../../../src/pages/marina-tower-floor-plans.tsx" /* webpackChunkName: "component---src-pages-marina-tower-floor-plans-tsx" */),
  "component---src-pages-marina-tower-residences-tsx": () => import("./../../../src/pages/marina-tower-residences.tsx" /* webpackChunkName: "component---src-pages-marina-tower-residences-tsx" */),
  "component---src-pages-marina-tower-tsx": () => import("./../../../src/pages/marina-tower.tsx" /* webpackChunkName: "component---src-pages-marina-tower-tsx" */),
  "component---src-pages-press-tsx": () => import("./../../../src/pages/press.tsx" /* webpackChunkName: "component---src-pages-press-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */)
}

