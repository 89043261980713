import React, { Dispatch, SetStateAction } from "react";

type ContextProps = {
  isMenuActive: boolean;
  isMenuLight: boolean;
  setIsMenuActive:  Dispatch<SetStateAction<boolean>>;
  setIsMenuLight: any;
};

export const Context = React.createContext<ContextProps>({
  isMenuActive: false,
  isMenuLight: false,
  setIsMenuLight(value: ((prevState: boolean) => boolean) | boolean): void {/* **/},
  setIsMenuActive(value: ((prevState: boolean) => boolean) | boolean): void {/* **/},
});
