import { css } from "styled-components";

export const sizes: any = {
  phoneXS: 340,
  phoneS: 370,
  phone: 376,
  phoneLarge: 414,
  phoneXL: 576,
  tabletS: 601,
  tablet: 769,
  tabletM: 800,
  tabletLandscape: 1025,
  desktopXS: 1100,
  desktopSmall: 1320,
  desktop: 1440,
  desktopM: 1600,
  desktopML: 1880,
  desktopLarge: 1920,
  desktopXLarge: 2048,
};

export const mediaMax = Object.keys(sizes).reduce((acc: any, label: string) => {
  acc[label] = (...args: any) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${
        // @ts-ignore
        css(...args)
      }
    }
  `;

  return acc;
}, {});
export const mediaMin = Object.keys(sizes).reduce((acc: any, label: string) => {
  acc[label] = (...args: any) => css`
    @media (min-width: ${(sizes[label] + 1) / 16}em) {
      ${
        // @ts-ignore
        css(...args)
      }
    }
  `;

  return acc;
}, {});

export const mediaMaxTablet = Object.keys(sizes).reduce(
  (acc: any, label: string) => {
    acc[label] = (...args: any) => css`
      @media (max-width: ${sizes[label] / 16}em) and (orientation: portrait) {
        ${
          // @ts-ignore
          css(...args)
        }
      }
    `;

    return acc;
  },
  {}
);

export const mediaMaxTabletLandscape = Object.keys(sizes).reduce(
  (acc: any, label: string) => {
    acc[label] = (...args: any) => css`
      @media (max-width: ${sizes[label] / 16}em) and (orientation: landscape) {
        ${
          // @ts-ignore
          css(...args)
        }
      }
    `;

    return acc;
  },
  {}
);
