import React, {FC, useEffect, useRef} from "react";
import {CompositionDiv, CompositionImage} from "./styled.index";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gsap from "gsap";

interface IComposition {
  imgSrc?: string;
  altText?: string;
  mobBreakpoint?: string | number;
  height?: string;
  mobHeight?: string;
  left?: string;
  mobLeft?: string;
  right?: string;
  mobRight?: string;
  bottom?: string;
  mobBottom?: string;
  top?: string;
  mobTop?: string;
  zIndex?: string;
  shape?: "circle" | "triangle";
  fade?: boolean;
  delay?: string;
}

const Composition: FC<IComposition> = ({
                                         imgSrc,
                                         altText,
                                         height,
                                         left,
                                         right,
                                         bottom,
                                         top,
                                         mobBreakpoint,
                                         mobHeight,
                                         mobLeft,
                                         mobRight,
                                         mobBottom,
                                         mobTop,
                                         shape,
                                         zIndex,
                                         delay,
                                         fade
                                       }) => {
  const CompositionImageRef = useRef<any>(null);
  useEffect(() => {
    ScrollTrigger.create({
      trigger: CompositionImageRef?.current || "",
      toggleActions: "play none none none",
      start: "top+=25% bottom-=15%",
      end: "center top",
      animation: gsap
        .timeline()
        .fromTo(
          CompositionImageRef?.current || null,
          1.25,
          {opacity: 0, y: fade ? 0 : 40, ease: "Power2.easeIn"},
          {opacity: 1, y: 0, ease: "Power2.easeOut"},
          delay ? delay : '+=.75'
        )
    });
  }, []);

  return shape ?
    <CompositionDiv
      ref={CompositionImageRef}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      height={height}
      mobBreakpoint={mobBreakpoint}
      mobHeight={mobHeight}
      mobLeft={mobLeft}
      mobRight={mobRight}
      mobBottom={mobBottom}
      mobTop={mobTop}
      className={shape}
    />
    :
    <CompositionImage
      ref={CompositionImageRef}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      height={height}
      src={imgSrc}
      alt={altText}
      zIndex={zIndex}
    />
};

export default Composition;
