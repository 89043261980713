import React, {FC, useEffect, useState} from "react";
import Header from "../header";
import RotateScreen from "../rotate";
import { Context } from "../../context";
import { GlobalStyle, LayoutWrapper } from "./index.styled";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CSSPlugin } from "gsap/CSSPlugin";
import useWindowSize from "../../helpers/UseWindowSize";
import {ContactBtn, MenuBtn, MenuBtnWrap} from "../header/index.styled";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, CSSPlugin);
gsap.config({
  nullTargetWarn: false,
});

export interface ILayoutProps {
  children: React.ReactNode;
}

const Layout: FC<ILayoutProps> = ({ children }) => {
  const {width} = useWindowSize();
  const [isMenuActive, setIsMenuActive] = useState<boolean>(false);
  const [isMenuLight, setIsMenuLight] = useState<boolean>(false);
  useEffect(() => {
    setIsMenuLight(false);
  }, [])
  return (
    <Context.Provider value={{ isMenuActive, setIsMenuActive, isMenuLight, setIsMenuLight }}>
        <GlobalStyle />
        <Header />
        <ContactBtn to={'/contact'} menulight={isMenuLight}>Contact</ContactBtn>
        <MenuBtnWrap onClick={() => setIsMenuActive(!isMenuActive)} menulight={isMenuLight}>
          <MenuBtn menulight={isMenuLight}/>
        </MenuBtnWrap>
      {( width > 500 && width < 1024) &&  <RotateScreen/> }
        <LayoutWrapper>
          <>{children}</>
        </LayoutWrapper>
    </Context.Provider>
  );
};
export default Layout;
