import styled from "styled-components";
import {mediaMax} from "../../helpers/MediaQueries";
import {Link} from "gatsby";

export const ContentWrap = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  z-index: 99999;
  background-color: #fff;
  h3 {
    font-size: 16px;
    font-weight: 400;
  }
`;
export const MainLogo = styled(Link)`
  max-width: 280px;
  width: 100%;
  margin: 40px auto 10vh;
  ${mediaMax.tablet`
    max-width: 265px;
  `}
`;
export const RotateLogo = styled(`img`)`
  width: 20%;
  margin: 5vh 0 40px;
`;