import React, { FC, useContext, useRef } from "react";
import { Link } from "gatsby";
import { Context } from "../../context";
import { HeaderWrap, MenuNav, MenuNavWrap } from "./index.styled";
import { navData } from "../../data/nav";

const Header: FC = () => {
  const { isMenuActive, setIsMenuActive } = useContext(Context);
  const btMenuRef = useRef<HTMLLIElement | null>(null);
  const mtMenuRef = useRef<HTMLLIElement | null>(null);
  const {
    introduction,
    film,
    location,
    team,
    press,
    legal,
    download,
    contact,
    beachTower,
    beachTowerRes,
    marinaTower,
    marinaTowerRes,
    beachTowerFloorPlans,
    marinaTowerFloorPlans
  } = navData;

  function toggleActive(e: any) {
    btMenuRef.current!.classList.remove('active');
    mtMenuRef.current!.classList.remove('active');
    e.target.closest('.list-btn').classList.add('active');
  }
  return (
    <HeaderWrap>
      <MenuNavWrap className={isMenuActive ? "active" : ""}>
        <MenuNav>
          <div
            className="back-btn"
            onClick={() => setIsMenuActive(!isMenuActive)}
          >
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.90576 29.9058L29.7048 10.1068" stroke="#B8B7AD" strokeWidth="2" strokeLinecap="round" />
              <path d="M9.90576 10.0942L29.7048 29.8932" stroke="#B8B7AD" strokeWidth="2" strokeLinecap="round" />
            </svg>
          </div>
          <ul className={"top-list"}>
            <li>
              <Link
                to={introduction.link}
                onClick={() => setIsMenuActive(!isMenuActive)}
              >
                {introduction.title}
              </Link>
            </li>
            <li ref={btMenuRef} onClick={() => toggleActive(event)} className={'list-btn'}>
              <span>
                {beachTower.title}
                <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.4714 6.4714C16.7318 6.21105 16.7318 5.78894 16.4714 5.52859L12.2288 1.28595C11.9684 1.0256 11.5463 1.0256 11.286 1.28595C11.0256 1.5463 11.0256 1.96841 11.286 2.22876L15.0572 6L11.286 9.77124C11.0256 10.0316 11.0256 10.4537 11.286 10.714C11.5463 10.9744 11.9684 10.9744 12.2288 10.714L16.4714 6.4714ZM5.82819e-08 6.66667L16 6.66667L16 5.33333L-5.82819e-08 5.33333L5.82819e-08 6.66667Z" fill="#5F5F5F" />
                  <path d="M11 0.999999L16 6L11 11" stroke="#5F5F5F" strokeWidth="1.33333" />
                </svg>
              </span>
              <ul className={"inner-list"}>
                <li>
                  <Link
                    onClick={() => setIsMenuActive(!isMenuActive)}
                    to={beachTower.link}
                  >
                    Amenities
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setIsMenuActive(!isMenuActive)}
                    to={beachTowerRes.link}
                  >
                    {beachTowerRes.title}
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setIsMenuActive(!isMenuActive)}
                    to={beachTowerFloorPlans.link}
                  >
                    {beachTowerFloorPlans.title}
                  </Link>
                </li>
              </ul>
            </li>
            <li ref={mtMenuRef} onClick={() => toggleActive(event)} className={'list-btn'}>
              <span>
                {marinaTower.title}
                <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.4714 6.4714C16.7318 6.21105 16.7318 5.78894 16.4714 5.52859L12.2288 1.28595C11.9684 1.0256 11.5463 1.0256 11.286 1.28595C11.0256 1.5463 11.0256 1.96841 11.286 2.22876L15.0572 6L11.286 9.77124C11.0256 10.0316 11.0256 10.4537 11.286 10.714C11.5463 10.9744 11.9684 10.9744 12.2288 10.714L16.4714 6.4714ZM5.82819e-08 6.66667L16 6.66667L16 5.33333L-5.82819e-08 5.33333L5.82819e-08 6.66667Z" fill="#5F5F5F" />
                  <path d="M11 0.999999L16 6L11 11" stroke="#5F5F5F" strokeWidth="1.33333" />
                </svg>
              </span>
              <ul className={"inner-list"}>
                <li>
                  <Link
                    onClick={() => setIsMenuActive(!isMenuActive)}
                    to={marinaTower.link}
                  >
                    Amenities
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setIsMenuActive(!isMenuActive)}
                    to={marinaTowerRes.link}
                  >
                    {marinaTowerRes.title}
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => setIsMenuActive(!isMenuActive)}
                    to={marinaTowerFloorPlans.link}
                  >
                    {marinaTowerFloorPlans.title}
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <ul className={"bottom-list"}>
            <li>
              <Link
                onClick={() => setIsMenuActive(!isMenuActive)}
                to={film.link}
              >
                {film.title}
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setIsMenuActive(!isMenuActive)}
                to={location.link}
              >
                {location.title}
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setIsMenuActive(!isMenuActive)}
                to={team.link}
              >
                {team.title}
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setIsMenuActive(!isMenuActive)}
                to={press.link}
              >
                {press.title}
              </Link>
            </li>
            <li>
              <Link
                onClick={() => setIsMenuActive(!isMenuActive)}
                to={contact.link}
              >
                {contact.title}
              </Link>
            </li>
          </ul>
          <div>
            <ul className={"socials"}>
              <li>
                <a href="https://www.facebook.com/TheResidencesPompanoBeach/" target={'_blank'}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8.847"
                    height="18.957"
                    viewBox="0 0 8.847 18.957"
                  >
                    <path
                      d="M129.4,3.671v2.61h-1.912V9.473H129.4v9.484h3.928V9.473h2.636s.247-1.53.366-3.2h-2.987V4.087a.927.927,0,0,1,.851-.765h2.14V0h-2.91C129.3,0,129.4,3.194,129.4,3.671"
                      transform="translate(-127.487)"
                      fill="#b8b3ad"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/theresidencespompanobeach/" target={'_blank'}>
                  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1351_3823)">
                      <path fillRule="evenodd" clipRule="evenodd" d="M14.4177 0.983887H6.10509C2.97081 0.983887 0.429688 3.525 0.429688 6.65928V14.9715C0.429688 18.1062 2.97081 20.6473 6.10509 20.6473H14.4177C17.552 20.6473 20.0931 18.1062 20.0931 14.9715V6.65928C20.0931 3.525 17.552 0.983887 14.4177 0.983887M18.2839 15.1179C18.2839 17.1987 16.5972 18.8854 14.5164 18.8854H6.00977C3.92887 18.8854 2.24219 17.1987 2.24219 15.1179V6.61083C2.24219 4.53037 3.92887 2.84326 6.00977 2.84326H14.5164C16.5972 2.84326 18.2839 4.53037 18.2839 6.61083V15.1179Z" fill="#B8B3AD"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M10.2607 5.74902C7.41797 5.74902 5.11328 8.05305 5.11328 10.8956C5.11328 13.7381 7.41797 16.0421 10.2607 16.0421C13.1035 16.0421 15.4077 13.7381 15.4077 10.8956C15.4077 8.05305 13.1035 5.74902 10.2607 5.74902M10.2623 14.2316C8.4195 14.2316 6.92578 12.7381 6.92578 10.8954C6.92578 9.05264 8.41949 7.55908 10.2623 7.55908C12.1052 7.55908 13.5989 9.05265 13.5989 10.8954C13.5989 12.7381 12.1052 14.2316 10.2623 14.2316" fill="#B8B3AD"/>
                    </g>
                    <path d="M15.3819 4.41113C14.66 4.41113 14.0742 4.99647 14.0742 5.71837C14.0742 6.44073 14.66 7.02607 15.3819 7.02607C16.1034 7.02607 16.6887 6.44073 16.6887 5.71837C16.6887 4.99647 16.1034 4.41113 15.3819 4.41113" fill="#B8B3AD"/>
                    <defs>
                      <clipPath id="clip0_1351_3823">
                        <rect width="19.6634" height="19.6634" fill="white" transform="translate(0.429688 0.983887)"/>
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </li>
            </ul>
            <ul className={"legal"}>
              <li>
                <Link to={download.link} onClick={() => setIsMenuActive(!isMenuActive)}>
                  {download.title}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="15.866"
                    height="19.708"
                    viewBox="0 0 15.866 19.708"
                  >
                    <defs>
                      <clipPath id="a">
                        <rect width="15.866" height="19.708" fill="#c9c3b8" />
                      </clipPath>
                    </defs>
                    <g clipPath="url(#a)">
                      <path
                        d="M10.624,11.633c.552-.4,1.061-.763,1.57-1.131.84-.608,1.675-1.222,2.519-1.823a1.068,1.068,0,1,1,1.235,1.743q-2.85,2.069-5.7,4.132a1.071,1.071,0,0,1-1.377,0q-2.854-2.063-5.7-4.132A1.069,1.069,0,1,1,4.4,8.682c1.293.928,2.58,1.865,3.87,2.8.058.042.119.079.217.144v-.8q0-4.869,0-9.738A1.067,1.067,0,0,1,10.6.845a1.786,1.786,0,0,1,.022.354q0,5.08,0,10.16v.274"
                        transform="translate(-1.623 0)"
                        fill="#c9c3b8"
                      />
                      <path
                        d="M7.934,44.372q3.434,0,6.869,0a1.064,1.064,0,0,1,.345,2.071,1.273,1.273,0,0,1-.392.06q-6.824,0-13.648,0a1.067,1.067,0,1,1,0-2.133q3.412,0,6.824,0"
                        transform="translate(0 -26.798)"
                        fill="#c9c3b8"
                      />
                    </g>
                  </svg>
                </Link>
              </li>
              <li>
                <Link to={legal.link} onClick={() => setIsMenuActive(!isMenuActive)}>{legal.title}</Link>
              </li>
            </ul>
          </div>
        </MenuNav>
      </MenuNavWrap>
    </HeaderWrap>
  );
};
export default Header;
