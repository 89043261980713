import React from "react";

export const navData = {
  introduction: {
    title: "Introduction",
    link: "/",
  },
  beachTower: {
    title: "The Beach Tower",
    link: "/beach-tower",
    next: (
      <>
        The <br /> Beach <br /> Tower
      </>
    ),
  },
  beachTowerRes: {
    title: "Residences",
    link: "/beach-tower-residences",
    next: (
      <>
        The <br /> Beach <br /> Tower <br /> Residences
      </>
    ),
  },
  beachTowerFloorPlans: {
    title: "Floorplans",
    link: "/beach-tower-floor-plans",
    next: (
      <>The <br /> Beach <br /> Tower <br /> Floorplans</>
    ),
  },
  marinaTower: {
    title: "The Marina Tower",
    link: "/marina-tower",
    next: (
      <>
        The <br /> Marina <br /> Tower
      </>
    ),
  },
  marinaTowerRes: {
    title: "Residences",
    next: (
      <>
        The <br /> Marina <br /> Tower <br /> Residences
      </>
    ),
    link: "/marina-tower-residences",
  },
  marinaTowerFloorPlans: {
    title: "Floorplans",
    link: "/marina-tower-floor-plans",
    next: (
      <>The <br /> Marina <br /> Tower <br /> Floorplans</>
    ),
  },
  film: {
    title: "Film",
    link: "/film",
    next: (<>Film</>),
  },
  location: {
    title: "Location",
    link: "/location",
  },
  team: {
    title: "Team",
    link: "/team",
  },
  press: {
    title: "Press",
    link: "/press",
  },
  contact: {
    title: "Contact",
    link: "/contact",
  },
  download: {
    title: "Download",
    link: "/download",
  },
  legal: {
    title: "Legal",
    link: "/legal",
  },
};
export default navData;
